
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  onMounted,
} from 'vue';

import PrimeVueTriStateCheckbox from 'primevue/tristatecheckbox';

import type {
  EntriesPaginator,
  Projects,
  Users,
  Campaigns,
} from '@/types';

import {
  TRAFFIC_TYPE_POP,
  TRAFFIC_TYPES,
  TRAFFIC_TYPES_MAP,
  TRAFFIC_CATEGORIES,
  TRAFFIC_CATEGORIES_MAP,
  ENTRIES,
  USER_TYPE_AFFILIATE,
  CAMPAIGN_PAYMENT_TYPE_CPC,
  CAMPAIGN_PAYMENT_TYPE_CPA,
  CAMPAIGN_PAYMENT_TYPES,
  CAMPAIGN_PAYMENT_TYPES_MAP,
  CAMPAIGN_TOO_HIGH_BID_POP,
  CAMPAIGN_APPROVAL_STATUSES,
  CAMPAIGN_APPROVAL_STATUSES_MAP,
  CAMPAIGN_STATUSES,
  CAMPAIGN_STATUSES_MAP,
  ADSECURE_CHECK_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    PrimeVueTriStateCheckbox,
    CampaignApprovalStatus: defineAsyncComponent(() => import('@/components/CampaignApprovalStatus.vue')),
    CampaignStatus: defineAsyncComponent(() => import('@/components/CampaignStatus.vue')),
    CampaignArchivedAt: defineAsyncComponent(() => import('@/components/CampaignArchivedAt.vue')),
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    showProjectColumn: {
      type: Boolean,
      default: true,
    },
    showUserColumn: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const { encodeStatisticsFilters } = useStatisticsLink();

    const campaigns = reactive<Campaigns>(ENTRIES);

    const campaignsFilters = reactive({
      project_id: null,
      user_id: null,
      name: null,
      traffic_type: null,
      traffic_category: null,
      payment_type: null,
      approval_status: null,
      status: null,
      archived: false,
    });

    const fetchCampaigns = async (campaignsPaginator?: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/campaign/getCampaignsPaginator',
        {
          ...(campaignsPaginator || {}),
          ...campaignsFilters,
          ...props.filters,
        },
      );

      Object.assign(campaigns, response.data);
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? campaignsFilters.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const onProjectChange = (): void => {
      campaignsFilters.user_id = null;

      fetchUsersWrapper();
    };

    const showArchived = (archived: boolean): void => {
      campaignsFilters.archived = archived;

      fetchCampaigns();
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isAffiliate() ? Promise.resolve() : fetchUsersWrapper(),
      ]);
    });

    // TODO: Вынести в Vue 3 фильтры, чтобы использовать везде
    const truncateText = (text: string, length: number): string => {
      if (text.length < length) {
        return text;
      }

      return `${text.substring(1, length)}...`;
    };

    return {
      TRAFFIC_TYPE_POP,
      TRAFFIC_TYPES,
      TRAFFIC_TYPES_MAP,
      TRAFFIC_CATEGORIES,
      TRAFFIC_CATEGORIES_MAP,
      CAMPAIGN_PAYMENT_TYPE_CPC,
      CAMPAIGN_PAYMENT_TYPE_CPA,
      CAMPAIGN_PAYMENT_TYPES,
      CAMPAIGN_PAYMENT_TYPES_MAP,
      CAMPAIGN_TOO_HIGH_BID_POP,
      CAMPAIGN_APPROVAL_STATUSES,
      CAMPAIGN_APPROVAL_STATUSES_MAP,
      CAMPAIGN_STATUSES,
      CAMPAIGN_STATUSES_MAP,
      ADSECURE_CHECK_STATUSES_MAP,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      encodeStatisticsFilters,
      campaigns,
      campaignsFilters,
      fetchCampaigns,
      onProjectChange,
      showArchived,
      truncateText,
    };
  },
});
